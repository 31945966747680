import { GeelyCarModelResponse } from "api"
import NextImage from "common/NextImage"
import React from "react"
import styled from "styled-components"

const StyledMainContainer = styled.div`
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: center;
    margin: 50px 200px 0 200px;
  }
`

const StyledImage = styled.div<{
  $width?: string
}>`
  position: relative;
  margin-bottom: 40px;
  object-fit: contain;

  > span {
    position: unset !important;
  }
  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 90vw;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: ${(p) => p.$width};
    object-fit: contain;
  }
`
const StyledRowCol = styled.div<{
  $isRow: boolean
}>`
  ${(p) => p.theme.breakpoints.up("md")} {
    max-width: 900px;
    display: flex;
    ${(p) => (p.$isRow ? `flex-direction: row` : `flex-direction: column`)};
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
`
const SafetyPollution = ({ data }: { data: GeelyCarModelResponse }) => {
  const pollutionData = data.airPollutionComponents
  return (
    <StyledMainContainer>
      <StyledRowCol $isRow={false}>
        <StyledRowCol $isRow={true}>
          <StyledImage $width="100%">
            <NextImage
              image={pollutionData[0]?.image?.imageUrl}
              alt={pollutionData[0]?.image?.alt}
              imageSizes="100vw"
            />
          </StyledImage>
        </StyledRowCol>
        <StyledRowCol $isRow={true}>
          <StyledImage $width="50%">
            <NextImage
              image={pollutionData[1]?.image?.imageUrl}
              alt={pollutionData[1]?.image?.alt}
              imageSizes={["100vw", "50vw"]}
            />
          </StyledImage>

          <StyledImage $width="50%">
            <NextImage
              image={pollutionData[2]?.image?.imageUrl}
              alt={pollutionData[2]?.image?.alt}
              imageSizes={["100vw", "50vw"]}
            />
          </StyledImage>
        </StyledRowCol>
        <StyledRowCol $isRow={true}>
          <StyledImage $width="50%">
            <NextImage
              image={pollutionData[3]?.image?.imageUrl}
              alt={pollutionData[3]?.image?.alt}
              imageSizes={["100vw", "50vw"]}
            />
          </StyledImage>
          <StyledImage $width="50%">
            <NextImage
              image={pollutionData[4]?.image?.imageUrl}
              alt={pollutionData[4]?.image?.alt}
              imageSizes={["100vw", "50vw"]}
            />
          </StyledImage>
        </StyledRowCol>
        <StyledRowCol $isRow={true}>
          <StyledImage $width="100%">
            <NextImage
              image={pollutionData[5]?.image?.imageUrl}
              alt={pollutionData[5]?.image?.alt}
              imageSizes="100vw"
            />
          </StyledImage>
        </StyledRowCol>
      </StyledRowCol>
    </StyledMainContainer>
  )
}

export default SafetyPollution
